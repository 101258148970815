<template>
  <div class="row h-100">
    <div class="col-12">
      <!-- Quick Edit -->
      <el-dialog :visible.sync="showQuickEditModal" class="pico-dialog">
        <span slot="title"
          ><QuickEditOutputModal @close="showQuickEditModal = false" v-if="showQuickEditModal" :existingOutput="currentEditingOutput" @save="changeOutputName"
        /></span>
        <span></span>
        <span slot="footer" class="dialog-footer"> </span>
      </el-dialog>
      <!-- Advanced Edit -->
      <el-dialog :visible.sync="showFullEditModal" class="min-dialog">
        <span slot="title"><OutputSettingsModal @close="showFullEditModal = false" v-if="showFullEditModal" @save="saveOutputEdit" :sensors="allSensors" /></span>
        <span></span>
        <span slot="footer" class="dialog-footer"> </span>
      </el-dialog>
      <card card-body-classes="table-full-width" class="h-100">
        <base-button
          size="sm"
          type="danger"
          class="button add-button"
          menu-on-right=""
          tag="div"
          title-classes="btn btn-link btn-icon"
          :class="{ 'float-left': $rtl.isRTL }"
          @click="forceDump"
        >
          Forza dump degli outputs
        </base-button>
        <base-button
          id="add-button"
          size="sm"
          type="info"
          class="button add-button"
          menu-on-right=""
          tag="div"
          title-classes="btn btn-link btn-icon"
          :class="{ 'float-left': $rtl.isRTL }"
          @click="
            currentEditingOutput = null;
            showFullEditModal = true;
          "
        >
          <i class="tim-icons icon-simple-add"></i>
        </base-button>
        <h4 slot="header" class="card-title">Elenco Output</h4>
        <div>
          <el-table :data="queriedData" :row-style="tableRowStyle" @row-click="rowClicked" :row-class-name="'custom-row'" :cell-style="{ padding: '0', height: '20px' }">
            <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth" :prop="column.prop" :label="column.label" align="left">
            </el-table-column>
            <el-table-column align="center" label="Azioni">
              <div slot-scope="props">
                <base-button @click.native="handleEdit(props.$index, props.row)" class="edit btn-link" type="danger" size="sm" icon>
                  <i class="tim-icons icon-pencil"></i>
                </base-button>
                <base-button @click.native="handleDelete(props.$index, props.row)" class="remove btn-link" type="danger" size="sm" icon>
                  <i class="tim-icons icon-simple-remove"></i>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <div class="">
            <p class="card-category">Mostrati {{ from + 1 }} a {{ to }} di {{ total }} risultati</p>
          </div>
          <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total"> </base-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { Table, TableColumn, Select, Option, Dialog } from "element-ui";
import { BasePagination } from "src/components";
import QuickEditOutputModal from "./OutputQuickModal.vue";
import OutputSettingsModal from "./OutputSettingsModal.vue";
import Fuse from "fuse.js";
import swal from "sweetalert2";
import config from "../../../config";

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    QuickEditOutputModal,
    OutputSettingsModal,
  },
  data() {
    return {
      showQuickEditModal: false,
      showFullEditModal: false,
      currentEditingOutput: null,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["name"],
      tableColumns: [
        {
          prop: "name",
          label: "Name",
          minWidth: 120,
        },
        {
          prop: "type",
          label: "Tipo",
          minWidth: 120,
        },
      ],
      tableData: [],
      searchedData: [],
    };
  },
  computed: {
    ...mapGetters({ selectedOutputID: "admin/getSelectedOutputID", outputs: "admin/getOutputs", sensors: "admin/getSensors", allSensors: "admin/getAllSensors" }),
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0 && this.searchQuery) {
        result = this.searchedData.map((el) => el.item);
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
    },
  },
  methods: {
    ...mapActions({
      selectOutputByID: "admin/selectOutputByID",
      refresh: "admin/refresh",
      addNewOutput: "admin/addNewOutput",
      patchExistingOutput: "admin/patchExistingOutput",
      putExistingOutput: "admin/putExistingOutput",
      forceOutputDump: "admin/forceOutputDump",
      removeOutput: "admin/removeOutput",
    }),
    startLoading() {
      this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
    },
    stopLoading() {
      this.$loading().close();
    },
    async saveOutputEdit(outputObj) {
      this.startLoading();
      if (this.currentEditingOutput) {
        console.log("Outputs.vue -> modifico output", outputObj);
        await this.putExistingOutput({ _id: outputObj._id, obj: outputObj });
      } else {
        console.log("Outputs.vue -> aggiungo output", outputObj);
        await this.addNewOutput(outputObj);
      }
      await this.refresh();
      this.showQuickEditModal = false;
      this.stopLoading();
    },
    async changeOutputName(outputObj) {
      this.startLoading();
      await this.patchExistingOutput({ _id: outputObj._id, obj: { name: outputObj.name } });
      await this.refresh();
      this.showQuickEditModal = false;
      this.stopLoading();
    },
    async forceDump() {
      this.startLoading();
      await this.forceOutputDump();
      this.stopLoading();
    },
    tableRowStyle({ row, rowIndex }) {
      if (this.selectedOutputID && this.selectedOutputID === row._id) {
        return { background: config.colors.info };
      }
    },
    rowClicked(row) {
      this.selectOutputByID(row._id);
    },
    handleEdit(index, row) {
      this.currentEditingOutput = JSON.parse(JSON.stringify(row));
      this.showQuickEditModal = true;
    },
    handleDelete(index, row) {
      swal
        .fire({
          title: "Sei sicuro?",
          text: `Azione irreversibile!`,
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: "Sì, cancella!",
          cancelButtonText: "Annulla",
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            this.startLoading();
            await this.removeOutput(row._id);
            this.stopLoading();
            swal.fire({
              title: "Deleted!",
              text: `Hai cancellato l'output ${row.name}`,
              icon: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          }
        });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id);
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    outputs(newValue) {
      this.tableData = (newValue || []).map((us) => {
        return { ...us, createdAt: new Date(us.createdAt).toLocaleDateString() };
      });
      if (newValue.length > 0) {
        this.selectOutputByID(newValue[0]._id);
      }
    },
  },
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content {
  font-size: inherit !important;
}
.custom-row {
  cursor: pointer;
}

#add-button {
  position: absolute;
  top: 20px;
  right: 27px;
}
</style>
